import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import NavbarStyleThree from "../components/_App/NavbarStyleThree";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import ContactInfo from '../components/Contact/ContactInfo';
import GoogleMap from '../components/Contact/GoogleMap';
import ContactForm from '../components/Contact/ContactForm';
import { graphql, StaticQuery } from "gatsby";
import { getImageURL } from "../format-date";
const CONTACT_PAGE_SEO_QUERY = graphql`
query GetContactUsPageSeoData {
    strapiContactUsPage {
        id
        seo_title
        seo_image {
          localFile{
            name
            extension
          }
        }
        seo_description
      }
  }
  
`;
const Contact = () => (
    <StaticQuery query={CONTACT_PAGE_SEO_QUERY} render={data => {
        return (
            <Layout>
                <SEO title={data.strapiContactUsPage.seo_title} description={data.strapiContactUsPage.seo_description} image={getImageURL(data.strapiContactUsPage.seo_image.localFile.name, data.strapiContactUsPage.seo_image.localFile.extension)} type="website" url="contact/" />
                <div className="bg-gradient">

                    <NavbarStyleThree />

                    <div className="">
                        <ContactForm />
                        {/* <ContactInfo /> */}
                    </div>
                    {/* <GoogleMap /> */}

                </div>

                <Footer />
            </Layout>
        )
    }} />
);

export default Contact;
import React from 'react';
import axios from 'axios';

// import ContactImg from "../../assets/images/contact-img.png";
export default class ContactForm extends React.Component {
    state = {
        contactForm: {
            name: "",
            email: "",
            mobileNumber: "",
            productType: "",
            message: "",
            contactType: 'header'
        }
    }

    handleNameChange(event) {
        let contactInfo = this.state.contactForm;
        contactInfo.name = event.target.value;
        this.setState({ contactForm: contactInfo });
    }
    handleEmailChange(event) {
        let contactInfo = this.state.contactForm;
        contactInfo.email = event.target.value;
        this.setState({ contactForm: contactInfo });
    }
    handlePhoneNumberChange(event) {
        let contactInfo = this.state.contactForm;
        contactInfo.mobileNumber = event.target.value;
        this.setState({ contactForm: contactInfo });
    }
    handleSubjectChange(event) {
        let contactInfo = this.state.contactForm;
        contactInfo.productType = event.target.value;
        this.setState({ contactForm: contactInfo });
    }
    handleMessageChange(event) {
        let contactInfo = this.state.contactForm;
        contactInfo.message = event.target.value;
        this.setState({ contactForm: contactInfo });
    }
    clearForm() {

        this.setState({
            contactForm: {
                name: "",
                email: "",
                mobileNumber: "",
                productType: "",
                message: "",
                contactType: 'header'
            }
        })
    }

    submitContactInformation(event) {
        event.preventDefault();
        axios.post("https://ionicfirebaseappfrontendapi.herokuapp.com/api/contacts", this.state.contactForm).then(res => {
            this.clearForm();
        });
    }

    render() {
        return (

            
            <div className="contact-area">
                   <div>
                <img src="https://ik.imagekit.io/ionicfirebaseapp/getwidget/gatsby-marketplace/Contact_us_3QW2ce9SA_z.png?updatedAt=1640669734251" className="img-fluid banner-img"/>
              
                </div>
                <div className="container-fluid plr-2">

         
            <div className="about-area ptb-80 pt-55">
                <div className="container-fluid padd-2">
                <div className="section-title">
                <h2>Get In Touch With Us</h2>
                                    <div className="bar"></div>
                                    <p>Anything On your Mind. We’ll Be Glad To Assist You!</p>
                                </div>
                    <div className="row align-items-center">
                       
                        <div className="col-lg-6 offset-lg-3 col-md-12">
                        <form id="contactForm" className='contactBlock'>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="name"
                                                placeholder="Your Name"
                                                className="form-control"
                                                value={this.state.contactForm.name}
                                                onChange={this.handleNameChange.bind(this)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="email"
                                                placeholder="Your email address"
                                                className="form-control"
                                                value={this.state.contactForm.email}
                                                onChange={this.handleEmailChange.bind(this)}

                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="number"
                                                placeholder="Your phone number"
                                                className="form-control"
                                                value={this.state.contactForm.mobileNumber}
                                                onChange={this.handlePhoneNumberChange.bind(this)}

                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="subject"
                                                placeholder="Your Subject"
                                                className="form-control"
                                                value={this.state.contactForm.productType}
                                                onChange={this.handleSubjectChange.bind(this)}

                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea
                                                name="text"
                                                cols="30"
                                                rows="5"
                                                placeholder="Write your message..."
                                                className="form-control"
                                                value={this.state.contactForm.message}
                                                onChange={this.handleMessageChange.bind(this)}

                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-sm-12">
                                        <button type="submit" className="btn btn-primary" onClick={this.submitContactInformation.bind(this)}>Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
                    {/* <div className="section-title">
                        <h2>Get In Touch With Us</h2>
                        <div className="bar"></div>
                        <p>Anything On your Mind. We’ll Be Glad To Assist You!</p>
                    </div> */}

                    {/* <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <img src="https://image.ionicfirebaseapp.com/gatsby-marketplace/contact-img_YNGHDUJYl.png?updatedAt=1640329267262" alt="image" />
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <form id="contactForm">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="name"
                                                placeholder="Your Name"
                                                className="form-control"
                                                value={this.state.contactForm.name}
                                                onChange={this.handleNameChange.bind(this)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="email"
                                                placeholder="Your email address"
                                                className="form-control"
                                                value={this.state.contactForm.email}
                                                onChange={this.handleEmailChange.bind(this)}

                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="number"
                                                placeholder="Your phone number"
                                                className="form-control"
                                                value={this.state.contactForm.mobileNumber}
                                                onChange={this.handlePhoneNumberChange.bind(this)}

                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="subject"
                                                placeholder="Your Subject"
                                                className="form-control"
                                                value={this.state.contactForm.productType}
                                                onChange={this.handleSubjectChange.bind(this)}

                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea
                                                name="text"
                                                cols="30"
                                                rows="5"
                                                placeholder="Write your message..."
                                                className="form-control"
                                                value={this.state.contactForm.message}
                                                onChange={this.handleMessageChange.bind(this)}

                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-sm-12">
                                        <button type="submit" className="btn btn-primary" onClick={this.submitContactInformation.bind(this)}>Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }
}